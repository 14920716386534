import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { libraryViewScreen, AccountMode } from "services/typewriter/segment";
import { LIBRARY_MODULE, ANALYTICS_LOCATION } from "constants/index";
import { useFamilyModeSetting } from "hooks/useFamilyModeSetting";
import { InstructorSearch } from "app/components/Algolia/InstructorSearch";
import { LibraryContainer } from "../LibraryContainer";

export const Instructors = () => {
  const { isFamilyModeOn } = useFamilyModeSetting();

  useEffect(() => {
    libraryViewScreen({
      screen_name: "Library - Instructors",
      location: ANALYTICS_LOCATION.library,
      module: LIBRARY_MODULE.library_instructors,
      account_mode: isFamilyModeOn ? AccountMode.Family : AccountMode.Studio,
    });
  }, []);

  return (
    <>
      <Helmet title="Instructors | Online Dance Classes and Tutorials" />
      <LibraryContainer>
        <InstructorSearch />
      </LibraryContainer>
    </>
  );
};
