import React from "react";
import { connectStateResults } from "react-instantsearch-dom";
import Flex from "app/components/Flex";
import EmptyStateList from "app/components/EmptyStateList";
import LoaderCentered from "./LoaderCentered";

export default connectStateResults(({ isSearchStalled, icon, listType }) =>
  isSearchStalled ? (
    <Flex width="100%" height="100%" mt="20%">
      <LoaderCentered />
    </Flex>
  ) : (
    <EmptyStateList icon={icon} listType={listType} />
  )
);
