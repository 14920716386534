import React from "react";
import styled from "styled-components";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import { H3, P1 } from "app/components/Typography";
import PropTypes from "prop-types";

const StyledIcon = styled.i`
  color: ${({ theme }) => theme.colors.monochrome[3]};
`;

const EmptyStateList = ({ icon, listType }) => (
  <Flex
    mt={{ _: 3, md: 6 }}
    width="100%"
    height="100%"
    justifyContent="center"
    alignItems="center"
  >
    <Flex
      width="40%"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
    >
      <Div mb={4}>
        <StyledIcon as={icon} width="52.5px" height="35px" />
      </Div>
      <H3 fontWeight="bold" color="monochrome.6" mb={2}>
        Looks like there are no {listType}
      </H3>
      <P1 color="monochrome.5" textTransform="none">
        Stay tuned as we continually release new content!
      </P1>
    </Flex>
  </Flex>
);

EmptyStateList.defaultProps = {
  icon: null,
  listType: "",
};

EmptyStateList.propTypes = {
  icon: PropTypes.node,
  listType: PropTypes.string,
};

export default EmptyStateList;
