import React from "react";
import { CenterWrapper, Container } from "../styles";

interface Props {
  children: React.ReactNode;
}

export function LibraryContainer({ children }: Props) {
  return (
    <CenterWrapper>
      <Container>{children}</Container>
    </CenterWrapper>
  );
}
