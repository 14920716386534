import Div from "app/components/Div";
import Flex from "app/components/Flex";
import styled from "styled-components";

export const CenterWrapper = styled(Flex).attrs(({ pb = 4 }) => {
  return {
    justifyContent: "center",
    pb,
  };
})``;

export const Container = styled(Div).attrs({
  px: [3, 3, 4],
  maxWidth: "1600px",
  width: "100%",
})``;
